<template>
  <div class="box">
    <img class="img" src="../../assets/shouye2_banner333.png" alt="" />
    <div class="mask"></div>
    <div class="content">
      <img src="../../assets/denglu_imgs@3x.png" class="content_img" alt="" />
      <div class="rigth">
        <img class="logo" src="../../assets/logo.png" alt="" />
        <div class="tab">
          <!-- <div class="item" @click="introduce">微信登录</div> -->
          <div class="item active">密码登录</div>
          <router-link to="/loginPhone" class="item">手机登录</router-link>
        </div>
        <div class="phone">
          <img src="../../assets/shouji_icon.png" alt="" />
          <input
            v-model="form.username"
            type="text"
            placeholder="请输入手机号"
          />
        </div>
        <div class="password">
          <img src="../../assets/mima_icon.png" alt="" />
          <input v-model="form.pwd" :type="val" placeholder="输入密码" />
          <img
            v-if="shows"
            @click="show(true)"
            style="cursor: pointer; margin-left: 20px"
            src="../../assets/yincang_icon.png"
            alt=""
          />
          <img
            v-else
            @click="show(false)"
            style="cursor: pointer; margin-left: 20px"
            src="../../assets/kejian_icon.png"
            alt=""
          />
        </div>

        <div class="password" style="margin-top: 10px">
          <img src="../../assets/yzm.jpg" alt="" />
          <input
            style="width: 130px"
            type="text"
            placeholder="请输入验证码"
            class="input-val"
            v-model.trim="yanzhen"
            @keyup.enter="login_enter"
          />
          <canvas id="canvas" width="130px" height="33px" @click="handleCanvas">
          </canvas>
        </div>

        <div class="btn" @click="confirm" @keyup.enter.native="confirm;">
          登 录
        </div>
        <div class="tac">
          还没账号？<span
            @click="goregister"
            style="cursor: pointer; color: #1276fb"
            >立即注册</span
          >
          <span @click="goforgetthepassword" style="cursor: pointer"
            >&nbsp;&nbsp;&nbsp;忘记密码</span
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  data() {
    return {
      val: "password",
      shows: true,
      form: {
        username: "",
        pwd: "",
      },
      yanzhen: "", //输入框双向绑定的值
      true_code: "", //保存正确的验证码
      yanzhen_arr: [], //只用于传参，并且数组长度不能「多于」下面验证码遍历的次数，不然最终得到的true_code会有问题
      //比如下面是4个验证码，可以是[1,2,3,4]及以下，但是不能是[1，2，3，4，5]， 因为5无法被替换
    };
  },
  mounted() {
    this.draw(this.yanzhen_arr);
    let g = this;
    document.onkeydown = function (e) {
      let key = window.event.keyCode;
      if (key == 13) {
        g.confirm();
      }
    };
  },
  methods: {
    ...mapActions(["getLogin", "getsmsVerificationr"]),
    draw(show_num) {
      var canvas_width = document.querySelector("#canvas").clientWidth;
      var canvas_height = document.querySelector("#canvas").clientHeight;
      var canvas = document.getElementById("canvas"); //获取到canvas
      var context = canvas.getContext("2d"); //获取到canvas画图
      canvas.width = canvas_width;
      canvas.height = canvas_height;
      var sCode =
        "a,b,b,c,d,e,f,g,h,i,j,k,l,m,n,o,p,q,r,s,t,u,v,w,x,y,z,1,2,3,4,5,6,7,8,9,0";
      var aCode = sCode.split(",");
      var aLength = aCode.length; //获取到数组的长度

      //4个验证码数
      for (var i = 0; i <= 3; i++) {
        var j = Math.floor(Math.random() * aLength); //获取到随机的索引值
        var deg = (Math.random() * 30 * Math.PI) / 180; //产生0~30之间的随机弧度
        var txt = aCode[j]; //得到随机的一个内容
        show_num[i] = txt.toLowerCase(); // 依次把取得的内容放到数组里面
        var x = 10 + i * 20; //文字在canvas上的x坐标
        var y = 20 + Math.random() * 8; //文字在canvas上的y坐标
        context.font = "bold 23px 微软雅黑";

        context.translate(x, y);
        context.rotate(deg);

        context.fillStyle = this.randomColor();
        context.fillText(txt, 0, 0);

        context.rotate(-deg);
        context.translate(-x, -y);
      }
      //验证码上显示6条线条
      for (var i = 0; i <= 5; i++) {
        context.strokeStyle = this.randomColor();
        context.beginPath();
        context.moveTo(
          Math.random() * canvas_width,
          Math.random() * canvas_height
        );
        context.lineTo(
          Math.random() * canvas_width,
          Math.random() * canvas_height
        );
        context.stroke();
      }
      //验证码上显示31个小点
      for (var i = 0; i <= 30; i++) {
        context.strokeStyle = this.randomColor();
        context.beginPath();
        var x = Math.random() * canvas_width;
        var y = Math.random() * canvas_height;
        context.moveTo(x, y);
        context.lineTo(x + 1, y + 1);
        context.stroke();
      }

      //最后把取得的验证码数组存起来，方式不唯一
      var num = show_num.join("");

      this.true_code = num;
    },
    //得到随机的颜色值
    randomColor() {
      var r = Math.floor(Math.random() * 256);
      var g = Math.floor(Math.random() * 256);
      var b = Math.floor(Math.random() * 256);
      return "rgb(" + r + "," + g + "," + b + ")";
    },
    //canvas点击刷新
    handleCanvas() {
      this.draw(this.yanzhen_arr);
    },

    goforgetthepassword() {
      this.$router.push({
        path: "/forgetthepassword",
      });
    },
    introduce() {
      this.$router.push("/wxlogin");
    },
    goregister() {
      this.$router.push("/register");
    },
    confirm() {
      if (this.form.username == "") {
        this.$message.error("请输入正确的账户");
        return;
      }
      if (this.form.pwd == "") {
        this.$message.error("请输入正确的密码");
        return;
      }
      if (this.yanzhen != this.true_code) {
        this.$message.error("请输入正确的验证码");
        return false;
      }
      const loading = this.$loading({
        lock: true,
        text: "登录中",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      this.getLogin(this.form).then((res) => {
        loading.close();
        this.$message({
          message: res.msg,
          type: res.code == 200 ? "success" : "cancel",
        });
        if (res.code == 200) {
          if (res.data.verification == 1) {
            this.$store.commit("SET_LEARNING", 1);
          } else {
            this.$store.commit("SET_LEARNING", 2);
            this.getsmsVerificationr({
              id: res.data.id,
              verify: "88888",
              phone: res.data.phone,
              username: res.data.username,
              type: 2,
            });
          }
          this.$store.commit("SET_forcetoexit", new Date().getTime());
          // this.getsmsVerificationr({
          //   id: res.data.id,
          //   verify: "88888",
          //   phone: res.data.phone,
          //   username: res.data.username,
          // });
          if (this.$route.query.type == 1) {
            this.$router.go(-1);
          } else {
            this.$router.push("/Layout/Learningcenter");
          }
        }
      });
    },
    show(val) {
      if (val) {
        this.shows = false;
        this.val = "text";
      } else {
        this.shows = true;
        this.val = "password";
      }
    },
    goregister() {
      this.$router.push("/register");
    },
  },
};
</script>

<style lang="scss" scoped>
.yz {
  // display: flex;
  // align-items: center;
  // justify-content: space-between;
}
#canvas {
  float: right;
  margin-right: 1%;
  display: block;
  border: 1px solid #ccc;
  border-radius: 5px;
  cursor: pointer;
  // margin-right: 80px;
}

.input-val {
  width: 50%;
  background: #ffffff;
  height: 30px;
  border-radius: 5px;
  border: none;
  padding: 0 0 0 0px;
  border: 1px solid rgba(0, 0, 0, 0.2);
}

input:focus {
  outline: none;
  border: 0 none;
}

.box {
  overflow-x: hidden;
  overflow-y: hidden;
  height: 100vh;
  position: relative;
  .img {
    width: 1922px;
    height: 531px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 100;
  }
  .mask {
    width: 1922px;
    height: 531px;
    background: rgba($color: #000000, $alpha: 0.3);
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 1001;
  }
  .content {
    display: flex;
    width: 786px;
    height: 448px;
    background: #ffffff;
    box-shadow: 0px 5px 20px 0px #06316a;
    border-radius: 10px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 1002;
    .content_img {
      width: 318px;
      height: 449px;
      border-radius: 10px 0px 0px 10px;
      vertical-align: middle;
    }
    .rigth {
      flex: 1;
      padding: 36px 64px;
      box-sizing: border-box;
      .logo {
        width: 143px;
        height: 48px;
      }
      .tab {
        margin-top: 42px;
        display: flex;
        justify-content: space-between;

        .item {
          cursor: pointer;
          font-size: 18px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #444444;
        }
        .active {
          font-size: 18px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #1276fb;
        }
      }
      .phone {
        margin-top: 41px;
        padding-bottom: 5px;
        border-bottom: 1px solid #d4d9e2;
        input {
          &::placeholder {
            font-size: 16px;
          }
          margin-left: 13px;
          transform: translateY(-3px);
          font-size: 20px;
          border: 0 none;
          height: 30px;
        }
      }
      .password {
        margin-top: 30px;
        padding-bottom: 5px;
        border-bottom: 1px solid #d4d9e2;
        input {
          &::placeholder {
            font-size: 16px;
          }
          width: 270px;
          margin-left: 13px;
          transform: translateY(-3px);
          font-size: 20px;
          border: 0 none;
          height: 30px;
        }
      }
      .btn {
        cursor: pointer;
        margin-top: 25px;
        text-align: center;
        line-height: 47px;
        height: 47px;
        background: #1276fb;
        border-radius: 24px;
        font-size: 18px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #ffffff;
      }
      .tac {
        margin-top: 8px;
        text-align: center;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #9c9c9c;
      }
    }
  }
}
</style>
